@import "src/assets/styles/variables";

.page-content-layout {
  background: white;
  padding: 20px 10px;
  border-radius: 2px;

  //&-actions {
  //  padding-right: 20px;
  //  border-right: 1px solid #efefef;
  //  width: 290px;
  //  @media screen and (max-width: $medium){
  //    padding-right: 0;
  //    border-right: 0;
  //    padding-bottom: 20px;
  //    border-bottom: 1px solid #efefef;
  //    width: 100%;
  //  }
  //}
  &-view {
    flex: 1;
    overflow-x: auto;
  }
  @media screen and (max-width: $medium) {
    flex-direction: column;
  }
  &--vertical {
    flex-direction: column;
    display: flex;
  }
}
